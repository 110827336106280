'use client';

import React, { useState } from 'react';
import { Locale } from '@fxtr/i18n';
import { Icon } from '@/components/atoms/Icon';
import { VrmLookupDumb } from '@/components/molecules/VrmLookupDumb';
import type { VrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';
import type { VrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import { VrmLookupDumbModal } from '@/components/molecules/VrmLookupDumbModal';

import styles from './index.module.scss';

interface SeeAllRepairsButtonProps {
  children: React.ReactNode;
  locale: Locale;
  readonly campaignId?: string;
  readonly modalTranslations: VrmLookupModalTranslations;
  readonly formTranslations: VrmLookupFormTranslations;
}

export const SeeAllRepairsButton = function SeeAllRepairsButton({
  children,
  locale,
  campaignId,
  modalTranslations,
  formTranslations,
}: SeeAllRepairsButtonProps): React.ReactNode {
  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className={styles.seeAllRepairs}
      onClick={() => locale === Locale.FR_FR && setShowModal(true)}
      onKeyDown={(e) => {
        if ((e.key === 'Enter' || e.key === ' ') && locale === Locale.FR_FR) {
          setShowModal(true);
        }
      }}
      role="button"
      tabIndex={0}
    >
      {locale === Locale.EN_GB ? <a href="/our-services">{children}</a> : <span>{children}</span>}
      <Icon id="arrows/arrow-circle-broken-right" width={24} height={24} legacy={false} color="#5739D8" />
      <VrmLookupDumbModal
        className={styles.toggleButton}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        triggeredFrom="see-all-repairs-button"
        {...modalTranslations}
      >
        <VrmLookupDumb
          sku={undefined}
          locale={locale}
          activeTab="REPAIRS"
          campaignId={campaignId}
          {...formTranslations}
        />
      </VrmLookupDumbModal>
    </div>
  );
};
